.picker-container {
  z-index: 10001;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  width: 100%;

  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .picker-inner {
    position: relative;

    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 20px;

    font-size: 1.2em;
    -webkit-mask-box-image: linear-gradient(
      to top,
      transparent,
      transparent 5%,
      white 20%,
      white 80%,
      transparent 95%,
      transparent
    );
  }

  .picker-column {
    flex: 1 1;
    position: relative;

    max-height: 100%;

    overflow: hidden;
    text-align: center;

    .picker-scroller {
      transition: 300ms;
      transition-timing-function: ease-out;
    }

    .picker-item {
      position: relative;

      padding: 0 10px;

      white-space: nowrap;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;

      &.picker-item-selected {
        color: #222;
      }
    }
  }

  .picker-highlight {
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;

    pointer-events: none;

    &:before,
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      right: auto;

      display: block;
      width: 100%;
      height: 1px;

      background-color: #d9d9d9;
      transform: scaleY(0.5);
    }

    &:before {
      top: 0;
      bottom: auto;
    }

    &:after {
      bottom: 0;
      top: auto;
    }
  }
}

// sp: special
// txt: text

.light-sm-txt {
  font-size: 0.75rem !important;
  font-weight: 300 !important;
}

.light-md-txt {
  font-size: 0.875rem !important;
  font-weight: 300 !important;
}

.light-lg-txt {
  font-size: 1rem !important;
  font-weight: 300 !important;
}

.light-xl-txt {
  font-size: 1.25rem !important;
  font-weight: 300 !important;
}

.regular-sm-txt {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}

.regular-md-txt {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.regular-lg-txt {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.regular-xl-txt {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
}

.medium-sm-txt {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
}

.medium-md-txt {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}

.medium-lg-txt {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.medium-xl-txt {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

.semiBold-sm-txt {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
}

.semiBold-md-txt {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}

.semiBold-lg-txt {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.semiBold-xl-txt {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

.semiBold-title-txt {
  font-size: 2.625rem !important;
  font-weight: 600 !important;
}

.bold-sm-txt {
  font-size: 0.75rem !important;
  font-weight: 700 !important;
}

.bold-md-txt {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
}

.bold-lg-txt {
  font-size: 1rem !important;
  font-weight: 700 !important;
}

.bold-xl-txt {
  font-size: 1.25rem !important;
  font-weight: 700 !important;
}

.bold-subtitle-txt {
  font-size: 3.125rem !important;
  font-weight: 700 !important;
}

.bold-title-txt {
  font-size: 4.5rem !important;
  font-weight: 700 !important;
}
